import PropTypes from 'prop-types';
import React, { useCallback, useMemo, Fragment, useState, useEffect } from "react";

import ConsultationFormMarketplace from '@product-site-frontend/shared/components/ConsultationFormMarketplace';
import { graphql, Link } from "gatsby";
import findIndex from 'lodash/findIndex';

import Breadcrumbs from '../../../../shared/components/Breadcrumbs';
import subtract from '../../assets/svg/subtract.svg';
import PageBox from '../../components/PageBox';
import SEO from '../../components/SEO';
import MarketplaceSlider from './components/marketplaceSlider';
import * as styles from './marketplace.module.css';

export const query = graphql`
  query MarketplacePageQuery {
    suppliers: allStrapiSuppliers {
      nodes {
        id
        slug
        name
        img {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
        suppliers_type {
          id
          name
        }
        hasSpecialOffer
      }
    },
    suppliers_type: allStrapiSuppliersType(sort: { order: ASC, fields: strapiId }) {
      nodes {
        id
        name
      }
    }
  }
`;

const MarketplacePage = ({ data }) => {
  const [typeOpen, setTypeOpen] = useState([]);

  const onClickScroll = useCallback((goToId) => (e) => {
    e.preventDefault();

    const section = document.querySelector(`#${goToId}`);
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const onClickListToggle = useCallback((toggleId) => (e) => {
    const newTypeOpen = [...typeOpen];
    newTypeOpen[toggleId] = !newTypeOpen[toggleId];

    setTypeOpen(newTypeOpen);
  }, [typeOpen]);

  const elAction = useCallback((showAction) => {
    if (showAction) {
      return (
        <div className={styles.supplierAction}>
          <img alt="Иконка акции" src={subtract} /> Акция
        </div>
      );
    }

    return '';
  }, []);

  const elSlider = useCallback((list) => {
    if (list?.length && list.length > 0) {
      const listToShow = list.filter((listElement) => {
        return listElement.hasSpecialOffer;
      });

      if (listToShow.length) {
        return (
          <div className={styles.sliderContainer}>
            <div className={styles.sliderTitle}>Специальные предложения <span>от поставщиков</span></div>
            <MarketplaceSlider suppliers={listToShow} />
          </div>
        );
      }

      return '';
    }

    return '';
  }, []);

  const elSuppliersList = useCallback((list, open) => {
    //TODO: костыль на вывод евразия групп в начало списка. в страпи id: 95
    let indexEG = findIndex(list, s => s.slug === "eurasia-group");

    if (indexEG !== -1) {
      list.unshift(list[indexEG])

      list.splice(indexEG + 1, 1);
    }

    let closedList = list.slice(0, 3).map((element) => (element));

    const renderFunction = (arrayToRender) => {
      return (
        arrayToRender.map((element, id) => (
          <Link className={styles.supplierLink} key={`partner_${id}`} to={`/marketplace/${element.slug}`}>
            <ul className={styles.supplierItem}>
              <li className={styles.supplierHeader}>
                <div className={styles.logo}>
                  <img
                    alt={element.name}
                    src={element.img.localFile.childImageSharp.gatsbyImageData.images.fallback.src}
                    title={element.name}
                  />
                </div>
              </li>
              <li className={styles.name}>{element.name}</li>
            </ul>
            {elAction(element.hasSpecialOffer)}
          </Link>
        ))
      );
    };

    if (list?.length && !open) {
      return (renderFunction(closedList));
    }

    if (list?.length && open) {
      return (renderFunction(list));
    }

    return '';
  }, [elAction]);

  const elSuppliersByType = useCallback((list, types) => {
    return (
      <div className={styles.category}>
        {types && (types.map((element, id) => {
          let listLength = 0;
          const viewAllTitle = typeOpen[id] ? 'Свернуть' : 'Смотреть все';
          const listForCategory = list.filter((supplierElement) => {
            if (supplierElement.suppliers_type.name === element.name) {
              listLength += 1;
            }
            return supplierElement.suppliers_type.name === element.name;
          });

          return (
            <Fragment key={`supplierCategory_${id}`}>
              <div className={styles.wrapper}>
                <div className={styles.categoryTitle}>
                  <div
                    className={styles.categoryTitleScrollTo}
                    id={`category_${id}`}
                  />
                  <span className={styles.categoryTitleName}>
                    {element.name}
                  </span>
                  {listLength && listLength > 3 && (<div className={styles.categoryOpenAll} onClick={onClickListToggle(id)}>{viewAllTitle} <span>{listLength}</span></div>)}
                </div>
                <div className={styles.categoryListWrapper}>
                  {elSuppliersList(listForCategory, typeOpen[id])}
                </div>
              </div>
            </Fragment>
          );
        }))}
      </div>
    );
  }, [elSuppliersList, typeOpen, onClickListToggle]);

  const elCategories = useMemo(() => {
    return (
      <div className={styles.categorySelector}>
        <div className={styles.categorySelectorCategoriesContainer}>
          {data?.suppliers_type?.nodes && (data.suppliers_type.nodes.map((element, id) => (
            <a
              className={styles.categoryLink}
              href="/marketplace"
              key={`cat_${id}`}
              onClick={onClickScroll(`category_${id}`)}
            >
              {element.name}
            </a>
          )))}
        </div>
      </div>
    );
  }, [data.suppliers_type, onClickScroll]);

  useEffect(() => {
    if (data?.suppliers_type?.nodes) {
      const arr = data.suppliers_type.nodes.map(() => {
        return false;
      });
      setTypeOpen(arr);
    }
  }, [data.suppliers_type.nodes]);

  return (
    <PageBox>
      <SEO pageData={{
        title: 'Выберите надежного поставщика оборудования для вашего бизнеса',
        description: 'Лизинг оборудования онлайн для малого бизнеса.'
      }} />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'Выбрать поставщика'
          }
        ]}
      />
      <div className={styles.headMarketplace}>
        <div className={styles.headMarketplaceWrapper}>
          <h1 className={styles.headMarketplaceTitle}><span>Выберите надежного поставщика</span> оборудования для вашего бизнеса</h1>
          <h2 className={styles.headMarketplaceSubtitle}>Все компании в данном разделе проверены и рекомендованы к сотрудничеству</h2>
        </div>
      </div>
      {elSlider(data?.suppliers?.nodes)}
      {elCategories}
      {elSuppliersByType(data?.suppliers?.nodes, data?.suppliers_type?.nodes)}
      <div className={styles.formBlock}>
        <div className={styles.formContainer}>
          <div className={styles.formTitle}>Выбрали поставщика?</div>
          <div className={styles.formSubtitle}>Получите финансирование оборудования!</div>
          <div className={styles.formScrollTo} id="form-marketplace">
            <div className={styles.formText}>Выберите необходимое оборудование на сайте сертифицированного партнера и узнайте, какую сумму вы можете получить на покупку этого оборудования прямо сейчас</div>
            <div>
              <ConsultationFormMarketplace />
            </div>
          </div>
        </div>
      </div>
    </PageBox>
  );
};

MarketplacePage.propTypes = {
  data: PropTypes.object
};

export default MarketplacePage;
